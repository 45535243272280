import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StandardSection = ({ standards, customizeStandardsClass, title }) => {
  return (
    <section className="brand-section w-full">
      <h3 className="poppins-regular w-full text-xl md:text-4xl">{title}</h3>
      <div className="brands-logo w-full px-4 md:px-6 md:py-0 overflow-hidden">
        <div className="scroll-container w-full">
          <div className="scroll-imgs w-full flex justify-content-around">
            {standards.map((logo, index) => (
              <div
                className={`logo-item ${customizeStandardsClass}`}
                key={`brand${index}`}
              >
                <div className="img-container w-full">
                  <LazyLoadImage
                    width={logo.width}
                    height={logo.height}
                    src={logo.img}
                    alt="partners brands standards"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StandardSection;
