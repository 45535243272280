import "./BrandSection.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useRef, useEffect, useState } from "react";

function BrandSection({ title, logos, customizelogosClass }) {
  const scrollContainerRef = useRef(null);
  const [animationDuration, setAnimationDuration] = useState(0);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    // Calculate the total width and set the animation duration accordingly
    const totalScrollWidth =
      scrollContainer.querySelector(".scroll-imgs").scrollWidth;
    const duration = totalScrollWidth / 100; // Adjust speed as needed
    setAnimationDuration(duration);
  }, [logos]);

  return (
    <section className="brand-section w-full">
      <h3 className="poppins-regular w-full text-xl md:text-4xl">{title}</h3>
      <div className="brands-logo w-full px-4 md:px-6 md:py-0 overflow-hidden">
        <div
          className="scroll-container"
          ref={scrollContainerRef}
          style={{
            "--animation-duration": `${animationDuration}s`,
          }}
        >
          <div className="scroll-imgs w-full">
            {logos.map((logo, index) => (
              <div
                className={`logo-item ${customizelogosClass}`}
                key={`brand${index}`}
              >
                <div className="img-container w-full">
                  <LazyLoadImage
                    width={logo.width}
                    height={logo.height}
                    src={logo.img}
                    alt="partners brands logos"
                  />
                </div>
              </div>
            ))}
            {/* Duplicate the logos for smooth infinite scrolling */}
            {logos.map((logo, index) => (
              <div
                className={`logo-item ${customizelogosClass}`}
                key={`brand-duplicate${index}`}
              >
                <div className="img-container w-full">
                  <LazyLoadImage
                    width={logo.width}
                    height={logo.height}
                    src={logo.img}
                    alt="partners brands logos"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrandSection;
