import { Link } from "react-router-dom";
import "./HeroSection.scss";
import React from "react";

export default function HeroSection() {
  return (
    <section className="hero-section poppins-regular">
      <div className="cont h-25rem md:h-30rem">
        <div className="img-cont">
          <div className="wallpaperBg"></div>
        </div>
        <div className="heading-container">
          <h2 className="helve-font text-4xl md:text-7xl">
            AI powered nature <br /> and climate data analytics
          </h2>
        </div>
      </div>

      <div className="last-hero text-center md:text-left md:ml-8">
        <h2 className="helve-font text-xl md:text-6xl">
          compliance, risk <br /> analysis, management
        </h2>
        <ul className="px-6 md:px-4 ">
          <li>Easy to understand and actionable data</li>
          <li>Nature and climate risks in one platform</li>
          <li>Instant results save time and reduce costs</li>
        </ul>
        <button className="w-10 md:w-13rem">
          <Link to="https://calendly.com/kya-christian/30?month=2024-06">
            Book a demo
          </Link>
        </button>
      </div>
    </section>
  );
}
