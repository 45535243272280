import React, { useRef, useState } from 'react';
import '../../App.css';
import './ContactForm.scss';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import emailjs from '@emailjs/browser';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [desc, setDesc] = useState('');

  const form = useRef();
  const toast = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    

    emailjs 
      .sendForm('service_mioikdf', 'template_fgzpxpo', form.current, {
        publicKey: 'DardO3vKDtiXPmRm4',
      })
      .then(
        (res) => {
          console.log('sucess', res.text);
          setName("");
          setCompany("");
          setEmail("");
          setDesc("");
          toast.current.show({ severity: 'success', life:10000 , summary: 'Sent!', detail: 'Thanks for your message!' })
        },
        (error) => {
          toast.current.show({ severity: 'error', life:4000 , summary: 'Error!', detail: 'Oops, something went wrong, please try again later!' })
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <form className="contactForm grid" ref={form} onSubmit={sendEmail}>
      <div className="col-8 col-offset-2">
        <div className="col-12 flex">
          <div className="col-6 pl-0">
            <label>Name</label>
            <InputText
              value={name}
              name="from_name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Company</label>
            <InputText
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              name="from_company"
            />
          </div>
        </div>
        <div className="col-12">
          <label>Email</label>
          <InputText
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12">
          <label>How can we help?</label>
          <InputTextarea
            name="message"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
        <div className="col-12">
          <Button
            type="submit"
            label="Submit"
            className="poppins-regular font-light"
            onClick={sendEmail}
          />
        </div>
      </div>
      <Toast ref={toast} />
    </form>
  );
}

export default ContactForm;
